import React, { useState } from "react";
import "./FAQ.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAnswer = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What services do you offer?",
      answer: "We offer web development, mobile app development, blockchain solutions, and custom IT services. Our blockchain solutions include smart contract development, decentralized applications (dApps), and blockchain integration for businesses.",
    },
    {
      question: "How can blockchain be integrated into my business?",
      answer: "Blockchain can be integrated into your business by improving transparency, security, and efficiency. We help create decentralized applications (dApps), implement smart contracts, and offer blockchain consultation for a wide range of industries.",
    },
    {
      question: "What is a smart contract?",
      answer: "A smart contract is a self-executing contract with the terms directly written into code. It runs on a blockchain network, ensuring automatic execution once predefined conditions are met, reducing the need for intermediaries.",
    },
    {
      question: "How does blockchain improve security?",
      answer: "Blockchain ensures enhanced security by decentralizing data and using cryptographic techniques to secure transactions. Once data is added to a blockchain, it becomes immutable, making it highly resistant to tampering and fraud.",
    },
    {
      question: "Do you offer blockchain development services?",
      answer: "Yes, we provide end-to-end blockchain development services, including blockchain architecture design, smart contract development, dApp creation, and blockchain integration with existing systems.",
    },
    {
      question: "How long will it take to develop a blockchain solution?",
      answer: "The timeline for a blockchain project depends on its complexity. For simple applications like token creation or smart contracts, it may take a few weeks. For more complex solutions like full-scale decentralized applications, it can take a few months.",
    },
    {
      question: "What technologies do you use for blockchain development?",
      answer: "We work with leading blockchain technologies such as Ethereum, Binance Smart Chain, Solana, Polkadot, and Hyperledger. We also specialize in Solidity, Web3.js, and other blockchain development tools.",
    },
  ];

  return (
    <section id="faq" className="faq-section">
      <h2 className="faq-title">Frequently Asked Questions</h2>
      <div className="faq-list">
        {faqs.map((faq, index) => (
          <div key={index} className="faq-item">
            <div
              className="faq-question"
              onClick={() => toggleAnswer(index)}
            >
              <h3>{faq.question}</h3>
              <span className={`faq-toggle-icon ${activeIndex === index ? "active" : ""}`}>
                {activeIndex === index ? "▲" : "▼"}
              </span>
            </div>
            {activeIndex === index && (
              <div className="faq-answer">
                <p>{faq.answer}</p>
              </div>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default FAQ;
