import React, { useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { NavLink, useNavigate } from 'react-router-dom';
import './Header.css';

const Header = ({ currentLanguage, onLanguageSwitch }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className={`main-header ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
      <div className="logo-container">
        <NavLink to="/">
          <img src="/Logo.png" alt="Logo" className="logo" />
        </NavLink>
      </div>
      <nav className={`main-navigation ${mobileMenuOpen ? 'mobile-menu-open' : ''}`}>
        <div className="menu-icon" onClick={toggleMobileMenu}>
          <div className={`bar top ${mobileMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar middle ${mobileMenuOpen ? 'open' : ''}`}></div>
          <div className={`bar bottom ${mobileMenuOpen ? 'open' : ''}`}></div>
        </div>

        <ul className={`${mobileMenuOpen ? 'open' : ''}`}>
          <li>
            <ScrollLink
              to="products"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active-bg"
              onClick={() => {
                closeMobileMenu();
                navigate('/');
              }}
            >
              Products
            </ScrollLink>
          </li>
          <li>
            <ScrollLink
              to="aboute"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active-bg"
              onClick={() => {
                closeMobileMenu();
                navigate('/');
              }}
            >
              About Us
            </ScrollLink>

          </li>


          <li>
            <ScrollLink
              to="Resources"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active-bg"
              onClick={() => {
                closeMobileMenu();
                navigate('/');
              }}
            >
              Resources
            </ScrollLink>
          </li>
          <li className="dropdown">
            <ScrollLink
              to="team"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active-bg"
              onClick={() => {
                closeMobileMenu();
                navigate('/');
              }}
            >
              Team
            </ScrollLink>
            <div className="dropdown-content">
              {/* Add dropdown items */}
            </div>
          </li>
          <li className="dropdown">
            <ScrollLink
              to="Features"
              smooth={true}
              duration={500}
              spy={true}
              activeClass="active-bg"
              onClick={() => {
                closeMobileMenu();
                navigate('/');
              }}
            >
              Features
            </ScrollLink>
            <div className="dropdown-content">
              {/* Add dropdown items */}
            </div>
          </li>

        
          <li>
            <NavLink
              to="/services"
              className={({ isActive }) => (isActive ? 'active-bg-nav' : '')} // Ensure active-bg-nav is applied here
              onClick={closeMobileMenu}
            >
              Services
            </NavLink>
          </li>
        
          <li>
            <NavLink
              to="/bio"
              className={({ isActive }) => (isActive ? 'active-bg-nav' : '')} // Ensure active-bg-nav is applied here
              onClick={closeMobileMenu}
            >
              Bio
            </NavLink>
            
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
