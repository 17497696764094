import React, { useRef, useEffect, useState } from "react";
import { Link as ScrollLink } from "react-scroll";
import AOS from "aos";
import "aos/dist/aos.css";
import "./OurPartners.css"; // Import CSS styles
import EmblaCarousel from "embla-carousel";

const OurPartners = () => {
  const partnersRef = useRef(null);
  const [embla, setEmbla] = useState(null);
  const emblaRef = useRef(null);

  useEffect(() => {
    if (emblaRef.current) {
      const emblaInstance = EmblaCarousel(emblaRef.current, {
        loop: true,
        speed: 5,
        align: "start",
      });
      setEmbla(emblaInstance);

      return () => emblaInstance.destroy(); // Cleanup on unmount
    }
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1000 });

    // Auto-scroll logic with smoother transitions
    const carousel = partnersRef.current;
    let scrollDirection = 1; // 1 = right, -1 = left
    let autoScroll;

    const autoScrollFunc = () => {
      if (carousel) {
        carousel.scrollLeft += scrollDirection * 2; // Adjust scroll speed
        if (
          carousel.scrollLeft + carousel.clientWidth >= carousel.scrollWidth ||
          carousel.scrollLeft <= 0
        ) {
          scrollDirection *= -1; // Change direction
        }
      }
    };

    autoScroll = setInterval(autoScrollFunc, 40);

    return () => clearInterval(autoScroll); // Cleanup auto-scroll
  }, []);

  // Dragging logic for carousel
  let isDragging = false;
  let startX = 0;
  let scrollLeft = 0;

  const startDragging = (e) => {
    isDragging = true;
    startX = e.pageX - partnersRef.current.offsetLeft;
    scrollLeft = partnersRef.current.scrollLeft;
  };

  const stopDragging = () => {
    isDragging = false;
  };

  const onDragging = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - partnersRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    partnersRef.current.scrollLeft = scrollLeft - walk;
  };

  // Touch functionality for carousel
  const startTouch = (e) => {
    isDragging = true;
    startX = e.touches[0].pageX - partnersRef.current.offsetLeft;
    scrollLeft = partnersRef.current.scrollLeft;
  };

  const stopTouch = () => {
    isDragging = false;
  };

  const onTouchMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.touches[0].pageX - partnersRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed
    partnersRef.current.scrollLeft = scrollLeft - walk;
  };

  useEffect(() => {
    const carousel = partnersRef.current;

    // Adding touch event listeners with passive: false
    if (carousel) {
      carousel.addEventListener("touchstart", startTouch, { passive: false });
      carousel.addEventListener("touchend", stopTouch);
      carousel.addEventListener("touchmove", onTouchMove, { passive: false });
    }

    // Clean up event listeners on unmount
    return () => {
      if (carousel) {
        carousel.removeEventListener("touchstart", startTouch);
        carousel.removeEventListener("touchend", stopTouch);
        carousel.removeEventListener("touchmove", onTouchMove);
      }
    };
  }, []);

  return (
    <div id="ourpartners">
      <section className="partners-container">
        <h2>Our Trusted Sources</h2>
        <p>
  Here are some of our trusted sources who have made significant contributions to the industry.
</p>


        <div
          className="partners-carousel"
          ref={partnersRef}
          onMouseDown={startDragging}
          onMouseLeave={stopDragging}
          onMouseUp={stopDragging}
          onMouseMove={onDragging}
        >
          <div className="partners-list">
            {[
           
              {
                name: "Coinbase",
                img: "coinbase.png",
                desc: "Popular digital currency wallet and exchange.",
                link: "https://www.coinbase.com/",
              },
              {
                name: "Ripple",
                img: "ripple.png",
                desc: "Enterprise blockchain and cryptocurrency solutions.",
                link: "https://ripple.com/",
              },
              {
                name: "Ethereum Foundation",
                img: "ethereum.png",
                desc: "Smart contracts  applications.",
                link: "https://ethereum.org/",
              },
              {
                name: "Polygon",
                img: "polygon.png",
                desc: "Scalable blockchain infrastructure.",
                link: "https://polygon.technology/",
              },
              {
                name: "Cardano",
                img: "Cardano.png",
                desc: "Sustainable blockchain for smart contracts.",
                link: "https://cardano.org/",
              },
              {
                name: "Solana",
                img: "solana.png",
                desc: "High-performance blockchain supporting decentralized apps.",
                link: "https://solana.com/",
              },
              {
                name: "Avalanche",
                img: "Avalanche.png",
                desc: "Fast and low-cost blockchain network.",
                link: "https://www.avax.network/",
              },
              {
                name: "Chainlink",
                img: "chainlink.png",
                desc: "Decentralized oracle network for smart contracts.",
                link: "https://chain.link/",
              },
              {
                name: "Hyperledger",
                img: "hyperledger.png",
                desc: "Open-source blockchain frameworks for enterprises.",
                link: "https://www.hyperledger.org/",
              },
              {
                name: "Stellar",
                img: "stellar.png",
                desc: "Blockchain for financial services and cross-border payments.",
                link: "https://www.stellar.org/",
              },
            
              {
                name: "Cosmos",
                img: "cosmos.png",
                desc: "Interoperable blockchain ecosystem.",
                link: "https://cosmos.network/",
              },
          
              {
                name: "Hedera",
                img: "hedera.png",
                desc: "Enterprise-grade public ledger for fast transactions.",
                link: "https://hedera.com/",
              },
              {
                name: "VeChain",
                img: "vechain.png",
                desc: "Blockchain for supply chain solutions.",
                link: "https://www.vechain.org/",
              },
              {
                name: "Tron",
                img: "tron.png",
                desc: "Decentralized content sharing platform.",
                link: "https://tron.network/",
              },
              {
                name: "IOTA",
                img: "iota.png",
                desc: "Blockchain-based solutions for IoT devices.",
                link: "https://www.iota.org/",
              },
              {
                name: "NEO",
                img: "neo.png",
                desc: "Smart economy blockchain platform.",
                link: "https://neo.org/",
              },
              {
                name: "EOS",
                img: "eos.png",
                desc: "Blockchain protocol for decentralized applications.",
                link: "https://eosnetwork.com/",
              },
              {
                name: "Polkadot",
                img: "polkadot.png",
                desc: "Cross-chain interoperability blockchain.",
                link: "https://polkadot.network/",
              },
              {
                name: "Filecoin",
                img: "filecoin.png",
                desc: "Decentralized storage network.",
                link: "https://filecoin.io/",
              },
              {
                name: "Celo",
                img: "celo.png",
                desc: "Blockchain for mobile-friendly financial services.",
                link: "https://celo.org/",
              },
              {
                name: "Zcash",
                img: "zcash.png",
                desc: "Privacy-focused cryptocurrency.",
                link: "https://z.cash/",
              },
            ].map((partner, index) => (
              <div className="partner-item" key={index} ref={emblaRef}>
             
                  <div className="logo-part">
                  <img
                    src={`/partners2/${partner.img}`}
                    alt={`${partner.name} Logo`}
                    className="partner-logo"
                  />
                  </div>
                  <a
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                 
                  <h3>{partner.name}</h3>
                  <p>{partner.desc}</p>
                  <div className="double-click-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="size-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.042 21.672 13.684 16.6m0 0-2.51 2.225.569-9.47 5.227 7.917-3.286-.672ZM12 2.25V4.5m5.834.166-1.591 1.591M20.25 10.5H18M7.757 14.743l-1.59 1.59M6 10.5H3.75m4.007-4.243-1.59-1.59"
                    />
                  </svg>
                </div>
                </a>
               
              </div>
            ))}
        </div>
        </div>

     
      </section>
    </div>
  );
};

export default OurPartners;
