import 'core-js/stable'; // Polyfill for ES features
import 'regenerator-runtime/runtime'; // Polyfill for async/await
import React, { useEffect } from 'react';
import RoutesConfig from './routes'; // Import route configuration
import Footer from './components/Footer/Footer'; // Import footer component
import ReactGA from 'react-ga'; // Google Analytics
import { animateScroll as scroll } from 'react-scroll'; // Smooth scrolling
import { Analytics } from '@vercel/analytics/react'; // Vercel analytics
import ErrorBoundary from './components/ErrorBoundary'; // Custom error boundary
import { SpeedInsights } from "@vercel/speed-insights/react"
// import ThreeScene from './components/ThreeJs/ThreeScene';

const App = () => {
  useEffect(() => {
    // Initialize Google Analytics
    try {
      ReactGA.initialize('G-RZR37FSYKG', { debug: "true" }); // Enable debug mode
      console.log('Google Analytics initialized');
    } catch (error) {
      console.error('Failed to initialize Google Analytics:', error);
    }

    // Track page view
    try {
      ReactGA.pageview(window.location.pathname + window.location.search);
      console.log('Pageview tracked:', window.location.pathname + window.location.search);
    } catch (error) {
      console.error('Failed to track pageview:', error);
    }

    // Scroll to the top when the component mounts
    try {
      console.log('Scrolling to top...');
      scroll.scrollToTop();
    } catch (error) {
      console.error('Failed to scroll to top:', error);
    }
  }, []);

  return (
    <ErrorBoundary>
      <SpeedInsights />
      <div className="app">
        {/* Vercel analytics */}
        <Analytics />
        {/* Main content */}
        <main className="main-content">
        {/* <ThreeScene /> */}
          <RoutesConfig />
        </main>
        {/* Footer */}
        <Footer />
      </div>
    </ErrorBoundary>
  );
};

export default App;
