import React, { useEffect } from 'react';
import './CompanyBio.css'; // Assuming a CSS file for styling
import linkedinLogo from '../../assets/linkdin.png'; // Replace with your image path
import companyImage from '../../assets/bg.gif'; // Replace with your image path
import About from '../Aboute/Aboute';

const CompanyBio = () => {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="company-bio">
      {/* Split Screen Container */}
      <div className="bio-split">
        {/* Left Side: Content */}
        <div className="bio-content">
          <div className="bio-container">
            <h1 className="bio-title">Welcome to CodeRed</h1>
            <p className="bio-description">
              At CodeRed, we specialize in creating high-quality Web3 and blockchain-powered solutions tailored to meet the unique needs of your business. With over 6 years of experience and more than 50 successful projects, our team ensures efficient and innovative services for every client.
            </p>
            <div className="bio-services">
              <p>Our services include:</p>
              <ul>
                <li>Custom blockchain application development</li>
                <li>Smart contract creation and auditing</li>
                <li>Integration of decentralized technologies with existing platforms</li>
              </ul>
            </div>
            <p className="bio-tech">
              We leverage cutting-edge Web3 technologies such as Ethereum, Binance Smart Chain, and IPFS, alongside JavaScript frameworks (React, Next.js, Vue.js, Node.js, and Nest.js) to deliver exceptional results.
            </p>
            <About />
            <a
              href="https://www.linkedin.com/company/codered-digital"
              className="bio-link"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Visit CodeRed on LinkedIn"
            >
              <img src={linkedinLogo} alt="LinkedIn Logo" className="linkedin-icon" />
            </a>
          </div>
        </div>
        {/* Right Side: Static Image or Animation */}
        {/* <div className="bio-media">
          <img src={companyImage} alt="CodeRed Company" className="company-image" />
        </div> */}
      </div>
    </section>
  );
};

export default CompanyBio;
