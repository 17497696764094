import React, { useEffect } from 'react';
import 'aos/dist/aos.css';
import AOS from 'aos';
import './Aboute.css';

function About() {
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 200 });
  }, []);

  return (
    <div className="about-container" id="aboute">
      <span className="aboute-us">
        <h2>About Us</h2>
      </span>

      <div className="about-content">
        
        <div className="about-item" data-aos="fade-up">
          <h3><strong>5+ </strong></h3>
          <p><strong>Years in Industry</strong></p>
        </div>
        <div className="about-item" data-aos="fade-up">
          <h3><strong>50+ </strong></h3>
          <p><strong>Projects Successfully Done</strong></p>
        </div>
        <div className="about-item" data-aos="fade-up">
          <h3><strong>70+ </strong></h3>
          <p><strong>Employees</strong></p>
        </div>
        <div className="about-item" data-aos="fade-up">
          <h3><strong>40+ </strong></h3>
          <p><strong>Ongoing Partnerships</strong></p>
        </div>
        <div className="about-item" data-aos="fade-up">
          <h3><strong>20+ </strong></h3>
          <p><strong>Awards Won</strong></p>
        </div>
        <div className="about-item" data-aos="fade-up">
          <h3><strong>100% </strong></h3>
          <p><strong>Customer Satisfaction</strong></p>
        </div>
      </div>
    </div>
  );
}

export default About;
