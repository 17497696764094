import React, { useState } from 'react';
import './services.css';
import { NavLink } from 'react-router-dom';
import langAM from '../../Translations/lang_am.json';
import langEN from '../../Translations/lang_en.json';
import ServiceItem from './ServiceItem';

const Services = ({ currentLanguage }) => {
  const translations = currentLanguage === 'am' ? langAM : langEN;

  const blockchainServices = [
    { title: 'Custom Blockchain Development', description: 'Tailor-made blockchain solutions for your specific use case, ensuring scalability and security.', price: 2500 },
    { title: 'Web3 Integration', description: 'Seamless Web3 integration into your website or app for decentralized functionality.', price: 1000 },
    { title: 'Blockchain Wallet Creation', description: 'Build a secure and user-friendly cryptocurrency wallet for multiple blockchains (Ethereum, Bitcoin, etc.).', price: 1200 },
    { title: 'Blockchain API Development', description: 'Development of custom APIs for interacting with blockchain networks.', price: 1500 },
    { title: 'DeFi Mobile Application Development', description: 'Decentralized finance (DeFi) mobile apps for Android and iOS.', price: 2000 },
    { title: 'Smart Contract Development', description: 'Development of secure, scalable, and efficient smart contracts for platforms like Ethereum and Binance Smart Chain.', price: 1200 },
    { title: 'Cryptocurrency Development', description: 'Creation of your own cryptocurrency with custom features and blockchain.', price: 2500 },
    { title: 'Launchpad Development', description: 'Develop a launchpad for new tokens and projects to raise funds through Initial Coin Offerings (ICOs).', price: 3000 }
  ];

  const softwareServices = [
    { title: translations.services_Dev, description: 'Web development using the latest technologies.', price: 500 },
    { title: 'Design', description: 'User-friendly interfaces and visually stunning designs.', price: 300 },
    { title: 'DevOps', description: 'Streamlining development and deployment processes.', price: 700 },
    { title: 'SEO', description: 'Boost online visibility and organic traffic.', price: 200 },
    { title: 'UI/UX', description: 'Creating intuitive and delightful user interfaces.', price: 400 },
    { title: 'Content Writing', description: 'High-quality and engaging content creation.', price: 150 },
    { title: 'Mobile App Development', description: 'Custom mobile apps for iOS and Android.', price: 800 }
  ];

  const [visibleBlockchainCount, setVisibleBlockchainCount] = useState(5);
  const [visibleSoftwareCount, setVisibleSoftwareCount] = useState(5);

  return (
    <div id="priceplans">
      <section className="services-section">
        <div className="our-services">
          <h2>Our Services</h2>
        </div>

        {/* Blockchain/Web3 Development Section */}
        <div className="services-container">
          <h3>Blockchain/Web3 Development</h3>
          {blockchainServices.slice(0, visibleBlockchainCount).map((service, index) => (
            <ServiceItem 
              key={index}
              title={service.title}
              description={service.description}
              price={service.price}
            />
          ))}
          <div className='load-item'></div>
          {visibleBlockchainCount < blockchainServices.length && (
            <NavLink to="/services" className="load-more">
              learn more ...
            </NavLink>
          )}
        </div>

        {/* Software Development Section */}
        <div className="services-container">
          <h3>Software Development</h3>
          {softwareServices.slice(0, visibleSoftwareCount).map((service, index) => (
            <ServiceItem 
              key={index}
              title={service.title}
              description={service.description}
              price={service.price}
            />
          ))}
            <div className='load-item'></div>
          {visibleBlockchainCount < blockchainServices.length && (
            <NavLink to="/services" className="load-more">
              learn more ...
            </NavLink>
          )}
        </div>
      </section>
    </div>
  );
};

export default Services;
