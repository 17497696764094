import React from "react";
import "./CompanyTeam.css";

const teamMembers = [
  // {
  //   name: "Hayk Paytyan",
  //   role: "Chief Executive Officer",
  //   image: "/team/Hayk.jpg",
  //   linkedIn: "https://www.linkedin.com/in/hayk-paytyan/",
  // },
  {
    name: "Avetis T-Grigoryan",
    role: "Chief Technology Officer",
    image: "/team/Av.jpg",
    linkedIn: "https://www.linkedin.com/in/avetis-t-grigoryan-7872018b/",
  },
  {
    name: "Hakob Torosyan",
    role: "Front-end Developer",
    image: "/team/Hak.jpg",
    linkedIn: "https://www.linkedin.com/in/hakob-torosyan-861690224/",
  },
  {
    name: "Harut Azatyan",
    role: "Team Lead",
    image: "/team/Harut.jpg",
    linkedIn: "https://www.linkedin.com/in/harut-azatyan/",
    website: "https://devaura.site/",
  },
  {
    name: "Hayk Gevorgyan",
    role: "Full stack Developer ",
    image: "/team/Hayko.jpg",
    linkedIn: "https://www.linkedin.com/in/hayk-gevorgyan-25bb43233/",
  },
  // {
  //   name: "Gayane Andreasyan",
  //   role: "UI UX & Graphic Designer",
  //   image: "/team/Gay.jpg",
  //   linkedIn: "https://www.linkedin.com/in/gayane-andreasyan-069596210/",
  //   behance: "https://www.behance.net/gayaneandreasyan", // Adding Behance link
  // },
  {
    name: "Grigor Hovhannisyan",
    role: "Back-end developer",
    image: "/team/Grigor.jpg",
    linkedIn: "https://www.linkedin.com/in/grigor-hovhannisyan-52a5a8307/",
  },

];

const CompanyTeam = () => {
  return (
    <section id="team" className="company-team">
      <h2 className="team-title">Meet Our Team</h2>
      <div className="team-grid">
        {teamMembers.map((member, index) => (
          <div key={index} className="team-member">
            <img
              src={member.image}
              alt={`${member.name}`}
              className="team-member-image"
            />
            <h3 className="team-member-name">{member.name}</h3>
            <p className="team-member-role">{member.role}</p>
            <div className="team-member-links">
              <a
                href={member.linkedIn}
                target="_blank"
                rel="noopener noreferrer"
                className="team-member-linkedin"
              >
                <img
                  src="/icons/linkdin.png"
                  alt="LinkedIn"
                  className="linkedin-logo"
                />
              </a>
              {member.website && (
                <a
                  href={member.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="team-member-website"
                >
                  <img
                    src="/icons/website.png"
                    alt="Website"
                    className="website-logo"
                  />
                </a>
              )}
              {member.behance && (
                <a
                  href={member.behance}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="team-member-behance"
                >
                  <img
                    src="/icons/behance.png" // Add Behance icon path
                    alt="Behance"
                    className="behance-logo"
                  />
                </a>
              )}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default CompanyTeam;
