import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import Header from './components/Header/Header';
import Home from './pages/Home/Home';
import Resources from './pages/Resources/Resources';
import OurPartners from './pages/OurPartners/OurPartners';
import Products from './pages/Products/Products';
import ReferalLink from './pages/ReferalLink/ReferalLink';
import ContactUs from './pages/ContactUs/ContactUs';
import PrivatePolicy from "./components/Cookie/PrivacyPolicy";
import CompanyBio from "./pages/Bio/CompanyBio";
import CompanyTeam from './pages/CompanyTeam/CompanyTeam';
import FAQ from './pages/FAQ/FAQ';
import ServicesHome from './components/services/ServicesHome';

// Create a Layout component
const Layout = ({ children }) => {
  return (
    <div>
      {/* Add a link to scroll to the top */}
      <ScrollLink to="top" smooth="true" duration={500}>
        {/* Scroll to Top */}
      </ScrollLink>

      {/* Header component */}
      <Header />
     
      {/* Page content */}
      {children}
    </div>
  );
};

// Main RoutesConfig component
const RoutesConfig = () => {

  // Simulate fetching user authentication data
  useEffect(() => {
    
  }, []);

  return (
    <Routes>
      {/* Wrap each page component with the Layout */}
      <Route
        path="/"
        element={
          <Layout>
            <Home />
          </Layout>
        }
      />

      <Route path="/resources" element={<Layout><Resources /></Layout>} />
      <Route path="/plans-pricing" element={<Layout><OurPartners /></Layout>} />
      <Route path="/get-in-touch" element={<Layout><ContactUs /></Layout>} />
      <Route path="/products" element={<Layout><Products /></Layout>} />
      <Route path="/referal" element={<Layout><ReferalLink /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivatePolicy /></Layout>} />
      <Route path="/bio" element={<Layout><CompanyBio /></Layout>} />
      <Route path="/company-team" element={<Layout><CompanyTeam /></Layout>} />
      <Route path="/faq" element={<Layout><FAQ /></Layout>} />
      <Route path="/services" element={<Layout><ServicesHome /></Layout>} />

   Bio
    
    </Routes>
  );
};

export default RoutesConfig;