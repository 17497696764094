import React from "react";
import LogoImage from "../../assets/Logo.png"; // Importing the logo
import { FaPhone, FaEnvelope, FaWhatsapp, FaLinkedin } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  // Reusable contact link component
  const ContactLink = ({ href, icon: Icon, text }) => (
    <div className="contact-item">
      <a
        href={href}
        className="contact-link"
        target="_blank"
        rel="noopener noreferrer"
      >
        <Icon className="contact-icon" />
        <p>{text}</p>
      </a>
    </div>
  );

  // Data for contact information
  const contacts = [
    { href: "tel:+37496454503", icon: FaPhone, text: "+37496454503" },
    {
      href: "mailto:codered010110@gmail.com",
      icon: FaEnvelope,
      text: "codered010110@gmail.com",
    },
    { href: "https://wa.me/37496454503", icon: FaWhatsapp, text: "WhatsApp" },
    {
      href: "https://www.linkedin.com/company/codered-digital/?viewAsMember=true",
      icon: FaLinkedin,
      text: "LinkedIn",
    },
  ];

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Top Section */}
        <div className="footer-left">
          {/* Logo Section */}
          <div className="logo-container">
            <img src={LogoImage} alt="Company Logo" className="footer-logo" />
            
          </div>
          <div className="contact-info">
            {contacts.map((contact, index) => (
              <ContactLink
                key={index}
                href={contact.href}
                icon={contact.icon}
                text={contact.text}
              />
            ))}
          </div>
        </div>
        <div className="map">
          <iframe
            title="Google Map Location"
            className="google-map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12144.08449497968!2d44.500860!3d40.202264!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4041e21c08f1e4c1%3A0x0!2zNDDCsDEyJzA4LjEiTiA0NMKwMzAnMDMuMSJF!5e0!3m2!1sen!2s!4v1695678901234!5m2!1sen!2s"
            style={{
              width: "100%",
              height: "250px",
              border: "0",
              borderRadius: "25px",
            }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />

          {/* Copyright Text */}
          <p className="copyright-text">
            &copy; {currentYear} Company. All rights reserved.
          </p>
        </div>
        {/* Content Section */}
       
      </div>
    </footer>
  );
};

export default Footer;
