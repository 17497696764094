import React, { useEffect } from 'react';
import './services.css';
import langAM from '../../Translations/lang_am.json';
import langEN from '../../Translations/lang_en.json';
import ServiceItem from './ServiceItem';
import OurPartners from '../../pages/OurPartners/OurPartners';

const ServicesHome = ({ currentLanguage }) => {
    const translations = currentLanguage === 'am' ? langAM : langEN;
      useEffect(() => {
        // Scroll to the top of the page when the component mounts
        window.scrollTo(0, 0);
      }, []);

      const blockchainServices = [
        { title: 'Custom Blockchain Development', description: 'Tailor-made blockchain solutions for your specific use case, ensuring scalability and security.', price: 2500 },
        { title: 'Web3 Integration', description: 'Seamless Web3 integration into your website or app for decentralized functionality.', price: 1000 },
        { title: 'Blockchain Wallet Creation', description: 'Build a secure and user-friendly cryptocurrency wallet for multiple blockchains (Ethereum, Bitcoin, etc.).', price: 1200 },
        { title: 'Blockchain API Development', description: 'Development of custom APIs for interacting with blockchain networks.', price: 1500 },
        { title: 'DeFi Mobile Application Development', description: 'Decentralized finance (DeFi) mobile apps for Android and iOS.', price: 2000 },
        { title: 'Smart Contract Development', description: 'Development of secure, scalable, and efficient smart contracts for platforms like Ethereum and Binance Smart Chain.', price: 1200 },
        { title: 'Cryptocurrency Development', description: 'Creation of your own cryptocurrency with custom features and blockchain.', price: 2500 },
        { title: 'Launchpad Development', description: 'Develop a launchpad for new tokens and projects to raise funds through Initial Coin Offerings (ICOs).', price: 3000 },
        { title: 'DeFi Application Development', description: 'Develop decentralized finance applications for trading, lending, staking, and more.', price: 2500 },
        { title: 'DApp Development', description: 'Create decentralized applications (DApps) that operate on blockchain networks.', price: 1800 },
        { title: 'NFT Marketplace Development', description: 'Build a secure and scalable NFT marketplace for trading digital assets.', price: 3000 },
        { title: 'Tokenomics Creation Services', description: 'Design the economic structure and incentives for your token, ensuring sustainable growth and adoption.', price: 2000 },
        { title: 'ICO Website Development', description: 'Custom ICO website design and development to facilitate token sales and fundraising.', price: 1500 },
        { title: 'DeFi Wallet Development', description: 'Development of decentralized wallets for secure management of cryptocurrencies and DeFi assets.', price: 1500 },
        { title: 'Solana Development', description: 'Build fast and scalable decentralized applications (DApps) and tokens on the Solana blockchain.', price: 2000 },
        { title: 'Ethereum Development', description: 'Expert Ethereum blockchain development services for smart contracts, DApps, and token creation.', price: 1500 },
        { title: 'Binance Smart Chain Development', description: 'Leverage Binance Smart Chain for building decentralized applications (DApps) and tokens with high throughput.', price: 1500 },
        { title: 'Cross-Chain Integration', description: 'Develop solutions that enable interoperability between different blockchains, enhancing scalability and functionality.', price: 2200 }
      ];
      

  const softwareServices = [
    { title: translations.services_Dev, description: 'Web development using the latest technologies.', price: 500 },
    { title: 'Design', description: 'User-friendly interfaces and visually stunning designs.', price: 300 },
    { title: 'DevOps', description: 'Streamlining development and deployment processes.', price: 700 },
    { title: 'SEO', description: 'Boost online visibility and organic traffic.', price: 200 },
    { title: 'UI/UX', description: 'Creating intuitive and delightful user interfaces.', price: 400 },
    { title: 'Content Writing', description: 'High-quality and engaging content creation.', price: 150 },
    { title: 'Mobile App Development', description: 'Custom mobile apps for iOS and Android.', price: 800 },
    { title: 'Cloud Solutions', description: 'Providing scalable and reliable cloud infrastructure.', price: 750 },
    { title: 'E-commerce Development', description: 'Building online stores and e-commerce platforms.', price: 600 },
    { title: 'API Development', description: 'Creating secure and efficient APIs for integrations.', price: 550 },
    { title: 'Network Security', description: 'Securing networks with robust defenses and monitoring.', price: 900 },
    { title: 'Artificial Intelligence', description: 'Building intelligent systems to automate and optimize processes.', price: 1000 }
  ];
  

  return (
    <div id="priceplans">
      <section className="services-section">
        <div className="our-services-1">
          <h2>Our Services</h2>
        </div>

        {/* Blockchain/Web3 Development Section */}
        <div className="services-container">
          <h3>Blockchain/Web3 Development</h3>
          {blockchainServices.map((service, index) => (
            <ServiceItem 
              key={index}
              title={service.title}
              description={service.description}
              price={service.price}
            />
          ))}
        </div>

        {/* Software Development Section */}
        <div className="services-container">
          <h3>Software Development</h3>
          {softwareServices.map((service, index) => (
            <ServiceItem 
              key={index}
              title={service.title}
              description={service.description}
              price={service.price}
              />
              
          ))}
                  
              </div>
              
          </section>
          <OurPartners />
    </div>
  );
}

export default ServicesHome;
