import React, { useRef, useEffect } from 'react';
import * as THREE from 'three';
import styles from './HeroSlideCanvas.module.css';

const LinkedParticles = () => {
    const canvasRef = useRef(null);
    let particleTimer;
    const defaultColor = new THREE.Color(1, 0, 0);  // Red
    const hoverColor = new THREE.Color(0, 0, 1);    // Blue

    useEffect(() => {
        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(
            75,
            window.innerWidth / window.innerHeight,
            0.1,
            1000
        );
        const renderer = new THREE.WebGLRenderer({ antialias: true });
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x000000);
        canvasRef.current.appendChild(renderer.domElement);

        const particles = [];
        const maxParticles = 100;
        const linkDistance = 50;

        const createCircleTexture = () => {
            const canvas = document.createElement('canvas');
            const size = 256;
            canvas.width = size;
            canvas.height = size;

            const ctx = canvas.getContext('2d');

            // Gradient for the dot (using purple)
            const gradient = ctx.createRadialGradient(size / 2, size / 2, size / 8, size / 2, size / 2, size / 2);
            gradient.addColorStop(0, 'rgba(128, 0, 128, 0.8)'); // Purple
            gradient.addColorStop(1, 'rgba(255, 0, 255, 0)');  // Transparent purple
            ctx.fillStyle = gradient;
            ctx.fillRect(0, 0, size, size);

            ctx.beginPath();
            ctx.arc(size / 2, size / 2, size / 8, 0, Math.PI * 2);
            ctx.fillStyle = 'purple';  // Solid purple dot in the center
            ctx.fill();

            return new THREE.CanvasTexture(canvas);
        };

        const particleMaterial = new THREE.PointsMaterial({
            size: 10,
            map: createCircleTexture(),
            transparent: true,
            blending: THREE.AdditiveBlending,
            depthWrite: false,
        });

        const particleGeometry = new THREE.BufferGeometry();
        const positions = new Float32Array(maxParticles * 3);
        particleGeometry.setAttribute(
            'position',
            new THREE.BufferAttribute(positions, 3)
        );
        particleGeometry.setDrawRange(0, 0);

        const particleMesh = new THREE.Points(particleGeometry, particleMaterial);
        scene.add(particleMesh);

        // Default line color (Red)
        const lineMaterial = new THREE.LineBasicMaterial({
            color: defaultColor,
            transparent: true,
            opacity: 0.5,
        });

        const lineGeometry = new THREE.BufferGeometry();
        const lineMesh = new THREE.LineSegments(lineGeometry, lineMaterial);
        scene.add(lineMesh);

        camera.position.z = 300;

        let mouseActive = false;
        let lastMousePosition = { x: 0, y: 0 };
        let links = [];

        const addParticle = (x, y, lifespan) => {
            const newParticle = {
                position: new THREE.Vector3(x, y, 0),
                velocity: new THREE.Vector3(
                    (Math.random() - 0.5),
                    (Math.random() - 0.5),
                    (Math.random() - 0.5)
                ),
                lifespan: lifespan,
            };

            particles.push(newParticle);

            if (particles.length > maxParticles) {
                const removedParticle = particles.shift();
                links = links.filter(
                    (link) =>
                        link.source !== removedParticle && link.target !== removedParticle
                );
            }

            particleGeometry.setDrawRange(0, particles.length);

            const newLinks = [];

            if (particles.length > 1) {
                const previousParticle = particles[particles.length - 2];
                newLinks.push({
                    source: newParticle,
                    target: previousParticle,
                });
            }

            particles.forEach((otherParticle) => {
                if (newParticle === otherParticle) return;

                const distance = newParticle.position.distanceTo(otherParticle.position);

                if (distance < linkDistance && Math.random() < 0.3) {
                    newLinks.push({
                        source: newParticle,
                        target: otherParticle,
                    });
                }
            });

            links.push(...newLinks);
        };

        const updateParticles = () => {
            for (let i = 0; i < 2; i++) {
                if (Math.random() < 0.5) {
                    const x = (Math.random() - 0.5) * 400;
                    const y = (Math.random() - 0.5) * 400;
                    addParticle(x, y, 30);
                }
            }

            const positions = particleGeometry.attributes.position.array;
            const linePositions = [];

            if (particles.length === 0) {
                particleGeometry.setDrawRange(0, 0);
                return;
            }

            particles.forEach((particle, i) => {
                particle.lifespan -= 1;
                if (particle.lifespan <= 0) return;

                particle.position.add(particle.velocity);

                if (Math.abs(particle.position.x) > window.innerWidth / 2) {
                    particle.velocity.x *= -1.2;
                }
                if (Math.abs(particle.position.y) > window.innerHeight / 2) {
                    particle.velocity.y *= -1.2;
                }

                positions[i * 3] = particle.position.x;
                positions[i * 3 + 1] = particle.position.y;
                positions[i * 3 + 2] = particle.position.z;
            });

            const activeParticles = particles.filter((particle) => particle.lifespan > 0);
            const activeLinks = links.filter((link) => {
                return particles.includes(link.source) && particles.includes(link.target);
            });

            particles.length = 0;
            particles.push(...activeParticles);
            links.length = 0;
            links.push(...activeLinks);

            links.forEach((link) => {
                linePositions.push(
                    link.source.position.x,
                    link.source.position.y,
                    link.source.position.z,
                    link.target.position.x,
                    link.target.position.y,
                    link.target.position.z
                );
            });

            particleGeometry.attributes.position.needsUpdate = true;

            lineGeometry.setAttribute(
                'position',
                new THREE.Float32BufferAttribute(linePositions, 3)
            );
            lineGeometry.setDrawRange(0, linePositions.length / 3);
            lineGeometry.attributes.position.needsUpdate = true;
        };

        const animate = () => {
            updateParticles();
            renderer.render(scene, camera);
            requestAnimationFrame(animate);
        };

        const onMouseMove = (event) => {
            if (!mouseActive) return;

            const rect = canvasRef.current.getBoundingClientRect();

            const ndcX = ((event.clientX - rect.left) / rect.width) * 2 - 1;
            const ndcY = -((event.clientY - rect.top) / rect.height) * 2 + 1;

            const mouseVector = new THREE.Vector3(ndcX, ndcY, 0.5);
            mouseVector.unproject(camera);

            const dir = mouseVector.sub(camera.position).normalize();
            const distance = -camera.position.z / dir.z; // Assume Z-plane at 0
            const position = camera.position.clone().add(dir.multiplyScalar(distance));

            lastMousePosition = { x: position.x, y: position.y };
            addParticle(lastMousePosition.x, lastMousePosition.y, 70);
        };

        const startParticleCreation = () => {
            if (particleTimer) return;
            particleTimer = setInterval(() => {
                if (mouseActive) {
                    addParticle(lastMousePosition.x, lastMousePosition.y, 70);
                }
            }, 100);
        };

        const stopParticleCreation = () => {
            clearInterval(particleTimer);
            particleTimer = null;
        };

        const onMouseEnter = () => {
            mouseActive = true;
            lineMaterial.color = hoverColor; // Change line color to blue
            startParticleCreation();
        };

        const onMouseLeave = () => {
            mouseActive = false;
            lineMaterial.color = defaultColor; // Revert line color to red
            stopParticleCreation();
        };

        const onResize = () => {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
        };

        window.addEventListener('resize', onResize);
        canvasRef.current.addEventListener('mousemove', onMouseMove);
        canvasRef.current.addEventListener('mouseenter', onMouseEnter);
        canvasRef.current.addEventListener('mouseleave', onMouseLeave);

        animate();

        return () => {
            if (canvasRef.current) {
                window.removeEventListener('resize', onResize);
                canvasRef.current.removeEventListener('mousemove', onMouseMove);
                canvasRef.current.removeEventListener('mouseenter', onMouseEnter);
                canvasRef.current.removeEventListener('mouseleave', onMouseLeave);
                canvasRef.current.removeChild(renderer.domElement);
            }
        };
    }, []);

    return <div className={styles.canvasLayer} ref={canvasRef}></div>;
};

export default LinkedParticles;
