import React, { useState, useEffect, useMemo } from "react";
import {
  FaCode,
  FaMobileAlt,
  FaCogs,
  FaPaintBrush,
  FaComments,
  FaClock,
  FaBitcoin,
  FaHandsHelping,
  FaMousePointer, // Finger icon
} from "react-icons/fa";
import AOS from "aos";
import "aos/dist/aos.css";
import "./Features.css";

const FeatureItem = ({ Icon, title, onClick, isClicked }) => (
  <div
    className={`feature-item ${isClicked ? "clicked" : ""}`}
    onClick={onClick}
    data-aos="fade-up" // AOS attribute for animation
    data-aos-duration="800"
  >
    <Icon className="feature-icon" aria-label={title} />
    <h3>{title}</h3>
    {isClicked && <FaMousePointer className="click-icon" />} {/* Show finger pointer on click */}
  </div>
);

const Features = () => {
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [clickedIndex, setClickedIndex] = useState(null);

  useEffect(() => {
    AOS.init(); // Initialize AOS
  }, []);

  const featuresData = useMemo(
    () => [
      {
        Icon: FaBitcoin,
        title: "Web3 & Blockchain",
        description:
          "Innovative Web3 and blockchain solutions for decentralized applications and smart contracts.",
        detailedText:
          "Web3 and blockchain technology provide new ways for decentralized applications to operate. This includes smart contracts, dApps, and cryptocurrency integration.",
      },
      {
        Icon: FaCode,
        title: "Custom Development",
        description: "We provide tailored solutions to meet your unique business requirements.",
        detailedText:
          "Our team specializes in creating custom software solutions that are designed to meet your exact business needs and challenges.",
      },
      {
        Icon: FaMobileAlt,
        title: "Mobile Responsiveness",
        description: "Our designs and applications are optimized for seamless mobile experiences.",
        detailedText:
          "We ensure that all our websites and applications function smoothly across all mobile devices, offering a responsive, mobile-first design.",
      },
      {
        Icon: FaCogs,
        title: "Scalable Architecture",
        description: "Built with scalability in mind, ensuring your system can grow with your business.",
        detailedText:
          "Our development approach focuses on building systems that are easy to scale, adapting to your growing business needs over time.",
      },
      {
        Icon: FaPaintBrush,
        title: "Creative Design",
        description: "Beautiful and user-friendly designs that make a lasting impression.",
        detailedText:
          "Our design team ensures that all interfaces are visually appealing, intuitive, and enhance the user experience.",
      },
      {
        Icon: FaComments,
        title: "Effective Communication",
        description: "Transparent and open communication throughout the development process.",
        detailedText:
          "We believe in constant communication with clients, ensuring that you are always informed about the project progress.",
      },
      {
        Icon: FaClock,
        title: "Timely Delivery",
        description: "We value your time; our projects are delivered on schedule without compromising quality.",
        detailedText:
          "We prioritize meeting deadlines and ensuring that your projects are completed on time without sacrificing quality.",
      },
      {
        Icon: FaHandsHelping,
        title: "Collaborative Approach",
        description: "We work closely with clients to ensure their vision becomes a reality.",
        detailedText:
          "Our team emphasizes a collaborative development process, engaging clients at every step to ensure that the end result aligns perfectly with their goals and expectations.",
      },
    ],
    []
  );

  const handleFeatureClick = (feature, index) => {
    setSelectedFeature(feature);
    setClickedIndex(index);
    setTimeout(() => setClickedIndex(null), 600); // Reset clicked state after animation
  };

  const handleCloseModal = () => {
    setSelectedFeature(null);
  };

  return (
    <div id="Features" className="features-container">
    {/* Add a title for the Features section */}
    <h2 className="features-title">Our Features</h2>
  
    <div className="features-list">
      {featuresData.map((feature, index) => (
        <FeatureItem
          key={index}
          {...feature}
          isClicked={clickedIndex === index}
          onClick={() => handleFeatureClick(feature, index)}
        />
      ))}
    </div>
  
    {selectedFeature && (
      <div className="modal-overlay" onClick={handleCloseModal}>
        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
          {/* Show the icon in the modal */}
          <selectedFeature.Icon className="feature-icon" aria-label={selectedFeature.title} />
          <h3>{selectedFeature.title}</h3>
          <p>{selectedFeature.detailedText}</p>
          <button className="close-modal" onClick={handleCloseModal}>
            Close
          </button>
        </div>
      </div>
    )}
  </div>
  
  );
};

export default Features;
