import React, { useState } from 'react';

function ServiceItem({ title, description, price }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxDescriptionLength = 150; // Limit the initial description length

  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };

  const truncatedDescription = description.length > maxDescriptionLength
    ? description.substring(0, maxDescriptionLength) + "..."
    : description;

  return (
    <div className="service-item">
      <h4>{title}</h4>
      <p>
        {isExpanded ? description : truncatedDescription}
        {description.length > maxDescriptionLength && (
          <span 
            className="read-more" 
            onClick={toggleDescription}
            style={{ color: '#f6a800', cursor: 'pointer', fontWeight: 'bold' }}
          >
            {isExpanded ? " Read Less" : " Read More"}
          </span>
        )}
      </p>
      {/* <p>Starting at ${price}</p> */}
    </div>
  );
}

export default ServiceItem;
